import { required } from "@vuelidate/validators";
import { maxLength } from "vuelidate/lib/validators";
export default class Occupation {
    constructor() {
        this.id = null;
        this.description = null;
    }
    validations() {
        return {
            description: {
                required,
                maxLength: maxLength(255),
            },
        };
    }
}